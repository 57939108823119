<template>
  <div class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row center space-between">
        <h1 class="my-0">Dashboard</h1>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="center py-50 gap-20 col">
      <div class="w600 weight-300">
        Share this link with your customers. The customer will be linked to your
        account and can be managed from this portal.
      </div>
    </section>
    <section class="center pb-50 gap-20 col">
      <div class="w600 weight-300 box col gap-20">
        <div>Link for your customers to register:</div>
        <span class="bold ellipsis">{{ link }}</span>
        <div>
          <s-btn @click.native="copy" class="green">Copy</s-btn>
        </div>
      </div>
    </section>
    <section class="row center">
      <div class="pt-35 pb-5 w600 row center space-between">
        <h1 class="my-0">Company Logo</h1>
      </div>
    </section>
    <section class="row center">
      <form ref="formAvatar" class="none" enctype="multipart/form-data">
        <input type="file" @change="upload" name="logo" ref="fileAvatar" />
      </form>
    </section>
    <hr class="mt-15" />
    <section class="center col py-20">
      <div class="w600 weight-300 mb-20">Upload the company logo</div>
      <div class="relative">
        <div class="profile">
          <img class="pic" @load="isImage()" :src="avatarUrl" v-show="imageLoaded" />
          <s-icon height="100" v-show="!imageLoaded">account</s-icon>
          <pulse-loader :loading="load" color="white" size="10px" class="absolute"></pulse-loader>
        </div>
        <button @click="openPicker" type="button" class="white round camera center opacity-60">
          <s-icon class="">camera</s-icon>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      load: false,
      imageLoaded: false,
    };
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.link);
    },
    async upload(data) {
      let formData = new FormData(this.$refs.formAvatar);
      if (this.checkLimit(formData)) return;
      if (this.checkFileType(formData)) return;
      this.load = true;
      await this.$store.dispatch("auth/postResellerLogo", formData);
      this.load = false;
      this.imageLoaded = true;
      let cache = this.cacheVue + 1
      this.$store.commit('user/cacheVue', cache)
    },
    checkLimit(formData) {
      let size = formData.get("logo").size;
      let limit = 1000000;
      let env = process.env.VUE_APP_LIMIT;
      let mb = (env / limit).toFixed(0);
      if (size < limit) return false;
      this.$store.commit("notifications/push", {
        error: `Image too large, max size: ${mb} MB`,
      });
      return true;
    },
    checkFileType(formData) {
      let ext = formData.get("logo").type;
      ext = ext.toLowerCase();
      let match = ext.search(/jpg|jpeg|png|gif/);
      if (match >= 0) return false;
      ext = ext.split("/")[1];
      this.$store.commit("notifications/push", {
        error: `File type .${ext} is not supported. Supported file types are as follows: jpg, jpeg, png and gif.`,
      });
      return true;
    },
    openPicker() {
      this.$refs.fileAvatar.click();
    },
    isImage() {
      this.imageLoaded = true;
    },
  },
  computed: {
    link() {
      return process.env.VUE_APP_URL + "/registration/" + this.hash;
    },
    avatarUrl() {
      let domain = process.env.VUE_APP_URL + "/api/reseller/logo/";
      let url = domain + this.hash + "?" + this.cacheVue;
      return url;
    },
    ...mapState("user", ["company", "hash", "avatar", "cacheVue"]),
  },
};
</script>

<style scoped>
.box {
  background-color: #141a25;
  padding: 20px;
  box-sizing: border-box;
}

.profile {
  background-color: grey;
  height: 100px;
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.camera {
  position: absolute;
  right: 0px;
  bottom: 4px;
  padding: 2px;
  transition: all 0.2s ease;
}

.camera:hover {
  transform: scale(1.1);
  opacity: 1;
}

.camera:focus {
  background-color: #ffffff;
  transform: scale(1.1);
  opacity: 1;
}
</style>